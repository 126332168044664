@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "NewRoboto";
  src: url("../public/fonts/roboto/Roboto-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Brand";
  src: url("../public/fonts/helvetica/Helvetica_Neue_Condensed_Black.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Brand";
  src: url("../public/fonts/helvetica/Helvetica_Neue_Condensed_Black_Oblique.otf");
  font-weight: 400;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.root, html, body {
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}